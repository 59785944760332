<template>
  <div v-if="displayOnly" class="form-control-static">{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="fileExtensionRule"
    :customMessages="computedErrorMessages"
    mode="eager"
    ref="validator"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
  >
    <div class="file-input">
      <button type="button" class="btn btn-primary icon-af-upload margin-right" @click="buttonClick">{{ buttonText }}</button>
      <span v-if="files.length && !multiple" class="text-muted">
        <a href="#" class="text-danger icon-trash" @click.prevent="removeFile(0)"><span class="sr-text">Remove file</span></a>
        {{ files[0].name }}
      </span>
      <ul v-if="files.length && multiple" class="file-input-file-container list-unstyled">
          <li class="file-input-file-name text-muted text-smaller" v-for="(file, i) in files" :key="i">
            <a href="#" class="text-danger icon-trash" @click.prevent="removeFile(i)"><span class="sr-text">Remove file</span></a>
            {{ file.name }}
          </li>
      </ul>
      <input
        class="form-control sr-text"
        ref="file"
        type="file"
        :multiple="multiple"
        :name="name"
        :id="id"
        :accept="accept"
        :placeholder="placeholder"
        @change="fileChanged"
        :disabled="disabled"
      />
    </div>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
    <div v-if="limit && limitReached && tooManyFiles"
         class="error-message">File limit ({{ limit }}) has been reached.</div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";

export default {
  name: "EnInputFile",
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * Returns array of <File>
     * @model
     */
    value: {
      type: Array
    },
    /**
     * Sets readable text for the button.
     */
    buttonText: {
      default: 'Upload file',
      type: String
    },
    /**
     * The maximum number of files a user can upload.
     */
    limit: {
      default: 1,
      type: Number
    },
    /**
     * The type of files that are allowed to be uploaded.  Used in validation
     */
    accept: {
      default: '*',
      type: String
    },
  },
  data() {
    return {
      files: [],
      tooManyFiles: false
    }
  },
  computed: {
    limitReached() {
      return this.limit && this.files.length >= this.limit
    },
    fileExtensionRule() {
      if (this.accept !== '*') {
        return 'ext:' + this.accept.replace(/[. ]/g, '');
      } else {
        return ''
      }
    },
    multiple() {
      return this.limit > 1
    }
  },
  methods: {
    buttonClick() {
      this.$refs.file.click()
    },
    fileChanged(e) {
      this.tooManyFiles = false
      const files = e.target.files

      this.$refs.validator.validate(e).then(({valid}) => {

        if (!valid) {
          return
        }
        // const length = files.length
        for (const file of files) {
          if (this.limitReached) {
              this.tooManyFiles = true;
              this.$emit('error', 'limit-exceeded');
              break;
          }
          this.files.push(file)
        }

        this.update()
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.update()
    },
    update() {
      const dataTransfer = new DataTransfer();
      // Add your file to the file list of the object
      for (const file of this.files) {
        dataTransfer.items.add(file);
      }
      // Save the file list to a new variable
      const fileList = dataTransfer.files;
      this.$refs.file.files = fileList

      this.validator.syncValue(this.files)
      this.$emit('input', this.files)
    }
  }
};
</script>
<style lang="scss" scoped>
// .badge {
//   background: transparent;
//   color: $body-color;
//   border: 1px solid $body-color;
// }
</style>
