<template>
  <div v-if="displayOnly" class="form-control-static">{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
  <div class="input-group">
    <slot name="addon-before"></slot>
    <input
      ref="input"
      v-bind="$attrs"
      v-maska:[maskOptions]
      class="form-control"
      :type="$attrs.type || 'text'"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="displayValue"
      @input="handleInput"
      @change="handleChange"
      @blur="$emit('blur', $event)"
      :autocomplete="$attrs.autocomplete || 'off'"
    />
    <slot name="addon-after">
      <EnInputInnerAddon
        v-if="showClearButton"
        @click="clearInput"></EnInputInnerAddon>
    </slot>
  </div>
  <div v-if="hasError" class="error-message">
    {{ errorMessage }}
  </div>
  </EnValidationFieldWrapper>
</template>

<script>
import { vMaska } from "maska"
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
import EnInputInnerAddon from './EnInputInnerAddon.vue'

export default {
  name: "EnInputText",
  directives: {
    maska: vMaska
  },
  props: {
    /**
     * Provides input masking options to the input, see https://beholdr.github.io/maska/#/?id=options
     */
    maskOptions: {
      type: Object,
      default() {
        return null
      }
    },
    isClearable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EnValidationFieldWrapper,
    EnInputInnerAddon
  },
  mixins: [inputMixin],
  computed: {
    showClearButton() {
      return this.isClearable && this.displayValue?.length > 5
    }
  },
  methods: {
    clearInput() {
      this.displayValue = ""
      this.$refs.input.focus()
    }
  }
};
</script>
