<template>
  <div v-if="displayOnly" class="form-control-static">{{ showDollarSymbol ? '$' : '' }}{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
  <!-- validator will validate this instead of formatted currency -->
    <input type="hidden" v-model="value" />
    <div :class="{ 'input-group': showDollarSymbol }">
      <span v-if="showDollarSymbol" class="input-group-addon">$</span>
      <input
        class="form-control"
        type="text"
        :name="name"
        :id="id"
        :required="required"
        :placeholder="placeholder"
        v-model.trim="displayValue"
        @focus="onFocus"
        @focusout="onBlur"
        @keydown="filterLetters($event)"
        :disabled="disabled"
        ref="input"
        autocomplete="off"
        :data-unmasked="value"
      />
    </div>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
import debounce from "../utils/debounce";
export default {
  name: "EnInputCurrency",
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Rounds the return value to a whole number.
     */
    wholeNumber: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether or not to show the dollar symbol as an input-group addon.
     */
    showDollarSymbol: {
      type: Boolean,
      default: true,
    },
    /**
     * Sets a limit on how many decimal places should be allowed.  Use null for no limit
     */
    decimalLimit: {
      type: Number,
      default: 2
    },
    /**
     * Formats what is displayed in the input as a currency with commas and decimals; still returns a number for the value
     */
    formatDisplay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      focused: false
    }
  },
  mounted() {
    this.addCommas()
  },
  watch: {
    displayOnly() {
      this.addCommas()
    }
  },
  methods: {
    filterLetters(e) {
      // filters non-letters; allows numbers, $, comma, .
      // valid if ctrl or cmdkey (mac) is held down
      const currentValue = this.displayValue ? this.displayValue.toString() : ''
      const input = e.currentTarget
      const hasSelection = input.selectionStart !== input.selectionEnd
      const isDigit = /[\d\.]/.test(e.key)

      const isValid = isDigit || e.ctrlKey || e.metaKey;
      const isArrow = e.key.toLowerCase().includes('arrow')
      const isBackspaceTabOrDelete = ['Backspace', 'Delete', 'Tab'].indexOf(e.key) !== -1

      if (!isValid && !isArrow && !isBackspaceTabOrDelete) {
        return e.preventDefault();
      }
      // no decimals when we want whole number
      if (this.wholeNumber && e.key === '.') {
        e.preventDefault()
      }

      if (isDigit && !hasSelection && this.displayValue && currentValue.indexOf('.') !== -1) {

        // prevent if there is already a decimal
        if (e.key === '.') {
          e.preventDefault();
        }

        const selectionBeforeDecimal = currentValue.indexOf('.') >= input.selectionStart

        // prevent if already at 2 decimals
        if (!selectionBeforeDecimal && this.decimalLimit !== null && currentValue.split('.')[1].length === this.decimalLimit) {
          e.preventDefault()
        }
      }
    },
    unFormat(val) {
      if (typeof val === "string") {
        val = val.replace(/[^0-9^\.]/g, "");
      }
      if (this.wholeNumber) {
        val = parseFloat(val).toFixed(0);
        val = isNaN(val) ? '' : val
      }

      return val;
    },
    format(val) {
      return this.addCommas(val)
    },
    onFocus() {
      this.focused = true;
      this.displayValue = this.value
    },
    onBlur() {
      this.focused = false;
      this.displayValue = this.value
      this.validate()
    },
    addCommas(val) {
      if (this.formatDisplay && !this.focused) {

          let num = parseFloat(val)
          if (isNaN(num)) {
            return
          }

          if (typeof this.decimalLimit === 'number' && !this.wholeNumber) {
            num = num.toFixed(this.decimalLimit)
          }

          if (this.wholeNumber) {
            num = parseFloat(num).toFixed(0);
          }

          num = num.toString()

          if (num.indexOf('.') !== -1) {
            const split = num.split('.')
            return split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + split[1].substr(0, this.decimalLimit)
          }
          return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      }
      return val
    }
  },
};
</script>
