<template>
  <div class="selects">
    <EnForm ref="form">
      <template #fields>
        <div class="col">
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <EnInputText v-maska data-maska="#0/#0/##00" data-maska-tokens="0:[0-9]:optional" placeholder="mmddyyyy" required v-model="dob" @blur="handleBlur" :rules="ruleString" />
          </div>
          <div class="form-group">
            <label for="">Parsed date</label>
            <div class="form-control-static">{{ parsedDate }}</div>
          </div>
          <div class="form-group">
            <label for="">Back to date</label>
            <div class="form-control-static">{{ parsedDateReadable }}</div>
          </div>
          <div class="form-group">
            <label for="">Min Age</label>
            <EnInputDecimal v-model="minAge" :max-decimal="0"/>
          </div>
          <div class="form-group">
            <label for="">Age</label>
            <div class="form-control-static">{{ calculatedAge }}</div>
          </div>
          <div class="form-group">
            <label for="">Date component age</label>
            <EnInputDate  ref="date" v-model="dobVModel" displayOnly/>
            <EnInputDate v-model="dobVModel" :min-age="0" min-date="1/1/1900" max-date="1/1/2020" />
            <button class="btn btn-primary" @click="dobVModel = null">clear date</button>
          </div>
          <div class="form-group">
            <label for="">Age</label>
            <div class="form-control-static">{{ ageFromDateComponent }}</div>
          </div>
          <div class="form-group">
            <label for="">Mac test</label>
            <EnInputDate
              value="5/5/1990 12:00:00AM"
              :min-age="0" min-date="1/1/1900" max-date="1/1/2020"/>
          </div>
          <div class="form-group">
            <label for="">Date test</label>
            <EnInputDate
              value="1977-04-05T00:00:00"
              v-model="testDate"
              :min-age="0" min-date="1/1/1900" max-date="1/1/2020"/>
          </div>
        </div>
      </template>
    </EnForm>
    <div>
      <div class="h4">Validation State</div>

      <div>
        <div class="h6">Has Error</div>
        <div>{{ hasError }}</div>
      </div>

      <div>
        <div class="h6"><button type="button" @click="resetFormState">Reset Form State</button></div>
      </div>

      <div>
        <pre>
          {{ formState }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>

import { vMaska } from "maska"

export default {
  name: 'DateInput',
  directives: {
    maska: vMaska
  },
  data() {
    return {
      dob: 'July 2, 1987',
      dobVModel: 'July 2, 1987',
      minAge: 10,
      ageFromDateComponent: null,
      unixDate: '5/5/1990 12:00:00AM',
      testDate: "1977-04-05T00:00:00",
      form: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form = this.$refs.form
      this.resetFormState()
    })
  },
  computed: {
    parsedDate() {
      return Date.parse(this.dob)
    },
    parsedDateReadable() {
      return this.backToDate(this.parsedDate)
    },
    calculatedAge() {
      const birthdate = new Date(this.parsedDate)
      if (birthdate) {
        const ageDifMs = Date.now() - birthdate.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      }
      return null
    },
    ruleString() {
      return `is_date|min_age:${this.minAge}`
    },
    hasError() {
      return this.form?.formState?.invalid
    },
    formState() {
      return this.form?.formState
    }
  },
  watch: {
    dob () {
      this.ageFromDateComponent = this.$refs.date.getAge(this.dob)
    }
  },
  methods: {
    backToDate(parsed) {
      return new Date(parsed).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
      })
    },
    handleBlur() {
      if (isNaN(this.parsedDate)) {
        return
      }
      this.dob = this.parsedDateReadable
    },
    resetFormState() {
      this.$refs.form.resetFormState()
    }
  }
}
</script>

<style lang="scss">
.selects {

}
</style>
