<template>
  <div v-if="displayOnly" class="form-control-static">
    <slot name="display" :display-value="displayValue" :value="value">
      {{ displayValue }}
    </slot>
  </div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="computedRules"
    :customMessages="ssnErrorMessages"
    :mode="displayValue && displayValue.length < 8 ? 'lazy' : 'eager'"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <input
      class="form-control"
      type="text"
      :name="name"
      :id="id"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="displayValue"
      v-maska="maskaObj"
      :data-maska="activeMask"
      data-maska-tokens="?:[0-9-]"
      :data-unmasked="maskaObj.unmasked"
      autocomplete="off"
    />
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import { vMaska, Mask } from "maska"

import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputSsn",
  directives: {
    maska: vMaska
  },
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Allows EIN style formatting (##-#######).
     */
    allowEinFormat: {
      type: Boolean,
      default: true
    },
    /**
     * Allows dummy SSN values through the validation.
     */
    allowDummySsn: {
      type: Boolean,
      default: false
    },
    /**
     * If an unformatted string is passed to the component, the default masking will be set to the TIN format.
     */
    assumeTin: {
      type: Boolean,
      default: false
    },
    resetValidationOnMount: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      maskaObj: {
        masked: '',
        unmasked: ''
      },
      activeMask: '',
      baseMask: '##??######',
      tinMask: '##-#######',
      ssnMask: '###-##-####',
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.resetValidationOnMount) {
        this.validator.reset()
      }
    })
  },
  watch: {
    displayValue: {
      immediate: true,
      handler(val, prev) {

        if (!val) {
          return
        }
        if (!this.allowEinFormat) {
          this.activeMask = this.ssnMask
          return
        }
        if (this.assumeTin) {
          this.activeMask = this.tinMask
          // end if we think it's an initial value being set or a copy / paste
          // if (!prev || (val.length - prev.length) > 5) return
          return
        }
        if (val.length > 3) {
          const isTin = val.substring(2,3) === '-'
          const isSsn = val.substring(3,4) === '-'
          if (isSsn) {
            return this.activeMask = this.ssnMask
          } else if (isTin) {
            return this.activeMask = this.tinMask
          } else {
            this.activeMask = this.ssnMask
          }
        } else {
          this.activeMask = this.baseMask
        }
        // return this.activeMask = this.assumeTin ? this.tinMask : this.ssnMask
      }
    },
    maskedVal(val) {
      this.displayValue = val
    },
    displayOnly: {
      handler(val) {
        if (val) {
          this.maskaObj.masked = this.mask.masked(val)
          this.maskaObj.unmasked = this.value
        }
      },
      immediate: true
    }
  },
  computed: {
    mask() {
      return new Mask({
        mask: this.activeMask,
        tokens: {
          '?': {
            pattern: /[0-9-]/
          }
        }
      })
    },
    maskedVal() {
      return this.maskaObj?.masked
    },
    isSsn() {
      return this.ssnMask === this.activeMask
    },
    isEin() {
      return this.tinMask === this.activeMask
    },
    // length validation needs to include the formatting dashes
    computedRules() {
      const rules = []
      if (this.isSsn && !this.allowDummySsn) {
        rules.push('ssn_only')
      }

      if (this.isEin) {
        rules.push('length:10')
      } else {
        rules.push('length:11')
      }

      if (this.rules.length) {
        rules.push(this.rules)
      }
      return rules.join("|")
    },
    ssnErrorMessages() {
      let length

      if (this.isEin) {
        length = {length: 'TIN is not long enough.'}
      } else {
        length = {length: 'SSN is not long enough.'}
      }

      return {
        ...length,
        ...this.computedErrorMessages
      }
    }
  },
  methods: {
    format(val) {
      return this.mask.masked(val)
    },
    unFormat(val) {
      return this.mask.unmasked(val)
    }
  }
};
</script>
