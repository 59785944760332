<template>
  <div v-if="displayOnly" class="form-control-static">
    <slot name="display" :selections="selectedOptions">
      <span v-for="option in selectedOptions" :key="option.value" class="list-comma-separated" v-html="option.label"></span>
    </slot>
  </div>
  <EnValidationFieldWrapper v-else
                          :name="name"
                          :required="required"
                          :rules="optionsRules"
                          :customMessages="computedErrorMessages"
                          :veeOptions="veeOptions"
                          mode="aggressive"
                          :class="{'has-error': hasError}">
    <div class="button-group" role="group" :class="{justify: justifyButtons}">
      <div
        v-for="(option, i) in mappedOptions"
        class="button-group-btn"
        :key="option.id ? option.id : i">
        <input
          type="radio"
          :name="name"
          :id="option.id || name + option.value"
          :value="option.value"
          v-model="displayValue"
          :disabled="option.disabled || disabled"
        >
        <label :for="option.id ||name + option.value" :disabled="option.disabled || disabled">{{ option.label }}</label>

      </div>
    </div>
    <div v-if="hasError" class="error-message">{{ errorMessage }}</div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from './EnValidationFieldWrapper.vue';
import EnInputRadio from './EnInputRadio.vue';
import inputMixin from '../utils/input-mixin';
import optionsMixin from '../utils/options-mixin';
export default {
  name: 'EnInputButtonGroup',
  components: {
    EnValidationFieldWrapper,
    EnInputRadio
  },
  mixins: [inputMixin, optionsMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      default: ''
    },
    /**
     * Array of option objects with label and value keys
     * @example [{label: 'Red', value: 'red'}, {label: 'Blue', value: 'blue'}]
     */
    options: {
      type: Array,
      default() {
        return [{
          label: 'No options passed',
          value: ''
        }]
      }
    },
    /**
     * Stretches buttons horizontally to fill all available space.
     */
    justifyButtons: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .button-group {
    display: flex;

    &-btn {
      display: flex;
      overflow: hidden;

      &+ .button-group-btn {
        margin-left: -1px;
      }

      .justify & {
        flex-grow: 2;
      }

      &:first-child {
        label {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &:last-child {
        label {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      label {
        display: inline-block;
        flex-grow: 2;
        background-color: white;
        padding: 6px 12px;
        border: 1px solid color(blue);
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;

        &[disabled] {
          background-color: color(gray, 100);
          opacity: .5;
          pointer-events: none;
        }
      }

      input[type='radio'] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        overflow: visible;

        @include hover {
          + label {
            background-color: rgba(color(blue), .9);
            color: white;
          }
        }

        &:checked + label {
          background-color: color(blue);
          color: white;
        }
      }
    }
  }
</style>
