<template>
  <div v-if="displayOnly" class="checkbox-group">
    <label
        class="control-label"
        v-if="label || horizontal"
        :class="{ [labelClass]: horizontal }"
      >
        {{ label }} <span v-if="required && showRequired">*</span>
    </label>
    <div class="form-control-static" :class="{ [inputClass]: horizontal }">
      <slot name="display" :selections="selectedOptions">
        <span v-for="option in selectedOptions" :key="option.value" class="list-comma-separated" v-html="option.label"></span>
      </slot>
    </div>
  </div>

  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="optionsRules"
    :customMessages="computedErrorMessages"
    :veeOptions="veeOptions"
    mode="aggressive"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <div class="" role="group" :aria-labelledby="uniqueId">
      <label
        class="control-label"
        v-if="label || horizontal"
        :class="{ [labelClass]: horizontal }"
        :id="uniqueId"
      >
        {{ label }} <span v-if="required && showRequired">*</span>
      </label>
      <div :class="{ [inputClass]: horizontal }">
        <EnInputCheckbox
          v-for="(option, i) in mappedOptions"
          :key="option.id ? option.id : i"
          :name="name"
          :optionValue="typeof option === 'object' ? option.value : option"
          :label="typeof option === 'object' ? option.label : option"
          :id="option.id || `${name}-${i}`"
          v-model="displayValue"
          :required="required"
          :inline="inline"
          :disabled="option.disabled || disabled"
        >
          <template #tooltip v-if="option.tooltip">
            <EnTooltip :tooltip-text="option.tooltip"/>
          </template>
        </EnInputCheckbox>
        <div v-if="hasError" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import EnInputCheckbox from "./EnInputCheckbox.vue";
import inputMixin from "../utils/input-mixin.js";
import optionsMixin from "../utils/options-mixin.js";

export default {
  name: "EnInputCheckboxGroup",
  components: {
    EnValidationFieldWrapper,
    EnInputCheckbox,
  },
  mixins: [inputMixin, optionsMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      type: Array,
      default() {
        return []
      },
    },
    /**
     * Array of option objects with label and value keys.
     * @example [{label: 'Red', value: 'red'}, {label: 'Blue', value: 'blue'}]
     */
    options: {
      type: Array,
      default() {
        return [
          {
            label: "No options passed",
            value: "",
          },
        ];
      },
    },
    /**
     * Sets the label for the group of checkboxes.
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Sets 'checkbox-inline' style, makes checkboxes align horizontally.
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the group to display horizontally.
     */
    horizontal: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the class used for the label column when using horizontal layout.
     */
    labelClass: {
      type: String,
      default: "col-md-3",
    },
    /**
     * Sets the class used for the input column when using horizontal layout.
     */
    inputClass: {
      type: String,
      default: "col-md-9",
    },
    /**
     * Displays asterisk (*) next to the group label.
     */
    showRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uniqueId() {
      return Math.random().toString(36).slice(2);
    },
  },
};
</script>
