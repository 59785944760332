<template>
  <div v-if="displayOnly" class="form-control-static">{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <div :class="{ 'input-group': showPercentSymbol }">
      <input
        v-bind="$attrs"
        ref="input"
        class="form-control"
        type="text"
        :name="name"
        :id="id"
        :required="required"
        :placeholder="placeholder"
        v-model="displayValue"
        v-maska:[maskOptions]="maskObject"
        :disabled="disabled"

      />
      <span v-if="showPercentSymbol" class="input-group-addon">%</span>
    </div>
    <div v-if="hasError" class="error-message">{{ errorMessage }}</div>
  </EnValidationFieldWrapper>
</template>

<script>
import { vMaska } from "maska"
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputDecimal",
  directives: {
    maska: vMaska
  },
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Prevents entering a decimal.
     */
    wholeNumber: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets minimum number of decimal places for return value.
     */
    minDecimal: {
      type: Number,
      default: 1,
    },
    /**
     * Sets maximum number of decimal places for return value.
     */
    maxDecimal: {
      type: Number,
      default: 2,
    },
    /**
     * Adds a validation rule for requiring a number between 0 and 1.
     */
    forceFraction: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether or not to show the percent symbol as an input-group addon.
     */
    showPercentSymbol: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maskObject: {}
    }
  },
  mounted() {
    if (this.value && !this.wholeNumber) {
      this.displayValue = Number.parseFloat(this.value).toFixed(this.maxDecimal)
    }
  },
  computed: {
    mask() {
      return this.wholeNumber ? '9' : '0'
    },
    maskOptions() {
      return {
        mask: this.mask,
        postProcess: val => {
          if (!val) return ''

          if (val.includes('.')) {
            const [int, decimal] = val.split('.')
            const fmt = `${int}.${decimal ? decimal.substr(0, this.maxDecimal) : ""}`
            return fmt
          }

          return val
        },
        tokens: {
          0: {
            pattern: /[\d.]/,
            multiple: true,
          },
          9: {
            pattern: /\d/,
            multiple: true,
          }
        }

      }
    },
    mergedRules() {
      if (this.forceFraction) {
        const betweenRule = "between:0,1";
        let rules = betweenRule;
        if (this.rules.length) {
          rules = this.rules + "|" + betweenRule;
        }
        return rules
      }
    },
  },
};
</script>
